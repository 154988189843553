import React, { Component } from 'react'
import styled from 'styled-components'

// import logo from '../images/logo-franz-spanraft.svg'
import LogoSVG from '../images/inline-svgs/logo-franz-spanraft.svg'

const List = styled.ul`
  display: flex;
  margin-top: 42px;
`

const ListItem = styled.li`
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 4.4px;
  /* color: ${p => p.theme.headingColor}; */
  :nth-child(n + 2) {
      @media (max-width: 800px) {
      display: none;
    }
    margin-left: 100px;
    @media (max-width: 1000px) {
      margin-left: 60px;
    }
    @media (max-width: 900px) {
      margin-left: 50px;
    }
  }
  :nth-child(2) {
    margin-left: auto;
  }
`

const Logo = styled(LogoSVG)`
  width: 240px;
  height: auto;
  @media (max-width: 900px) {
    width: 200px;
  }
  @media (max-width: 600px) {
    width: 180px;
  }
`

const BurgerMenu = styled.li`
  height: 24px;
  margin-bottom: auto;
  margin-left: auto;
  cursor: pointer;
  /* z-index: 3; */
  @media (min-width: 801px) {
    display: none;
  }
`

const BurgerMenuIcon = styled.span`
  display: block;
  width: 26px;
  height: 3px;
  background-color: ${p => p.theme.headingColor};
  border-radius: 10px;
  transform: translateY(10px);
  transition: 0.1s;
  background-color: ${({ mobileNavIsHidden }) => !mobileNavIsHidden && 'transparent'};
  &:before {
    content: '';
    display: block;
    width: 26px;
    height: 3px;
    background-color: ${p => p.theme.headingColor};
    transform: translateY(-8px);
    border-radius: 10px;
    transition: 0.15s ease-out;
    background-color: ${p => !p.mobileNavIsHidden && p.theme.primaryColor};
    transform: ${({ mobileNavIsHidden }) => !mobileNavIsHidden && 'translateY(0px) rotate(45deg)'};
  }
  &:after {
    content: '';
    display: block;
    width: 26px;
    height: 3px;
    background-color: ${p => p.theme.headingColor};
    transform: translateY(5px);
    border-radius: 10px;
    transition: 0.15s ease-out;
    background-color: ${p => !p.mobileNavIsHidden && p.theme.primaryColor};
    transform: ${({ mobileNavIsHidden }) => !mobileNavIsHidden && 'translateY(-3px) rotate(-45deg)'};
  }
`

class Navbar extends Component {
  render() {
    const { toggleMobileNav, mobileNavIsHidden } = this.props
    return (
      <List>
        <ListItem>
          <a
            href="#Home"
            aria-label="Logo - Installateurmeister Franz Spanraft"
            onClick={e => this.props.goToSection(e, 'Home')}
          >
            <Logo alt="Logo - Installateurmeister Franz Spanraft" />
          </a>
        </ListItem>
        <ListItem>
          <a href="#Leistungen" onClick={e => this.props.goToSection(e, 'Leistungen')}>
            Leistungen
          </a>
        </ListItem>
        <ListItem>
          <a href="#Preise" onClick={e => this.props.goToSection(e, 'Preise')}>
            PREISE
          </a>
        </ListItem>
        <ListItem>
          <a href="#Kontakt" onClick={e => this.props.goToSection(e, 'Kontakt')}>
            KONTAKT
          </a>
        </ListItem>
        <BurgerMenu onClick={toggleMobileNav}>
          <BurgerMenuIcon mobileNavIsHidden={mobileNavIsHidden} />
        </BurgerMenu>
      </List>
    )
  }
}

export default Navbar
