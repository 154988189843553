import React from 'react'
import styled from 'styled-components'

import CloseIcon from '../images/inline-svgs/close-circle.svg'
import Emphasized from './styles/Emphasized'

const ContainerOuter = styled.div`
  position: fixed;
  /* display: flex; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: rgba(45, 49, 66, 0.5);
`

const Container = styled.div`
  /* display: flex; */
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  /* bottom: 0; */
  /* right: 0; */
  /* z-index: 4; */
  z-index: 8;
  /* text-align: center; */
  /* height: 100vh; */
  width: 80%;
  max-width: 480px;
  /* display: inline-block; */
  @media (max-width: 600px) {
    max-width: 380px;
    width: 85%;
  }
`

const ImpressumOuter = styled.div`
  position: relative;
  /* margin: auto; */
  /* max-width: 80vw; */
  /* max-height: 80vh; */
  /* display: block; */
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 60px; */
`

const ImpressumInner = styled.div`
  overflow-y: auto;
  background-color: white;
  box-shadow: 5px 10px 30px 0 rgba(45, 49, 66, 0.2);
  border-radius: 8px;
  /* margin-bottom: 110px; */
  /* max-height: 80vh; */
`

const Content = styled.div`
  /* position: relative; */
  padding: 60px 80px 40px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 8px;
  max-width: 80vw;
  @media (max-width: 800px) {
    padding: 50px 60px 30px;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    padding: 40px 30px 30px;
    font-size: 14px;
  }
  @media (max-width: 400px) {
    padding: 40px 20px 30px;
    font-size: 14px;
  }
`

const Header = styled.div`
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 34px;
  color: ${p => p.theme.headingColor};
`

const Underline = styled.div`
  width: 25%;
  height: 2px;
  background-color: ${p => p.theme.primaryColor};
  opacity: 0.35;
  margin-top: 14px;
  margin-bottom: 36px;
  margin-left: auto;
  margin-right: auto;
`

const CloseIconComponent = styled(CloseIcon)`
  position: absolute;
  right: -20px;
  top: -20px;
  width: 44px;
  height: 44px;
  @media (max-width: 400px) {
    right: -17px;
    top: -17px;
    width: 38px;
    height: 38px;
  }
  & .close-circle {
    cursor: pointer;
  }
  & .close-circle-outer {
    transition: 0.15s ease-out;
  }
  & .close-circle-x {
    transition: 0.15s ease-out;
  }
  & .close-circle:hover .close-circle-outer {
    fill: #fc5f64;
  }
  & .close-circle:hover .close-circle-x {
    fill: white;
  }
`

class Impressum extends React.Component {
  render() {
    return (
      <ContainerOuter onClick={this.props.toggleImpressum}>
        <Container>
          <ImpressumOuter>
            <ImpressumInner>
              <Content>
                <Header>Impressum</Header>
                <Underline />
                <p>
                  <Emphasized>Installateurmeister Franz Spanraft</Emphasized>
                  <br />
                  <br />
                  Dopschstraße 2a/Parzelle 159, 1210 Wien (Nicht dauernd besetzt)
                  <br />
                  <a className="styled-link" href="tel:+4368181469538">
                    0681 814 69 538
                  </a>
                  <br />
                  <a className="styled-link" href="mailto:f.spanraft@gmx.at">
                    f.spanraft@gmx.at
                  </a>
                  <br />
                  <br />
                  <Emphasized>Unternehmensgegenstand:</Emphasized>
                  <br />
                  Gas- und Sanitärtechnik | Heizungstechnik
                  <br />
                  <br />
                  <Emphasized>Kammerzugehörigkeit:</Emphasized>
                  <br />
                  Wirtschaftskammer Wien
                  <br />
                  LI Sanitär-, Heizungs- und Lüftungstechniker
                  <br />
                  <br />
                  <Emphasized>Gewerbebehöre:</Emphasized>
                  <br />
                  Magistratisches Bezirksamt des XXI. Bezirkes
                  <br />
                  <br />
                  <Emphasized>Anwendbare Rechtsvorschriften:</Emphasized>
                  <br />
                  Gewerbeordnung:{' '}
                  <a className="styled-link" target="_blank" rel="noopener noreferrer" href="https://www.ris.bka.gv.at">
                    www.ris.bka.gv.at
                  </a>
                </p>
                <Underline style={{ marginTop: '40px', marginBottom: '36px' }} />
                <p>
                  <Emphasized>Web Design</Emphasized>
                  <br />
                  <a
                    className="styled-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.peterkroyer.at"
                  >
                    www.peterkroyer.at
                  </a>
                </p>
                <CloseIconComponent onClick={this.props.toggleImpressum} />
              </Content>
            </ImpressumInner>
          </ImpressumOuter>
        </Container>
      </ContainerOuter>
    )
  }
}

export default Impressum
