import styled from 'styled-components'

const TextHighlight = styled.div`
  color: ${p => p.theme.headingColor};
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  border-left: 4px solid ${p => p.theme.primaryColor};
  padding-left: 22px;
  margin-left: -26px;
  margin-top: 44px;
  margin-bottom: 66px;
  padding-top: 9px;
  padding-bottom: 9px;
  @media screen and (max-width: 1190px) {
    padding-left: 16px;
    margin-left: -20px;
  }
`

export default TextHighlight
