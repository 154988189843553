import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: ${p => p.theme.backgroundColor};
  padding-top: 48px;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  padding-bottom: ${p => (p.moreMargin ? '48px' : '48px')};
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  h1 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: ${p => p.theme.headingColor};
    font-weight: ${p => (p.smallHeading ? '400' : '700')};
    margin-bottom: ${p => (p.moreMargin ? '48px' : '36px')};
    ${p => (p.moreMargin ? 'margin-top: 16px;' : '')};
  }
  h2 {
    color: ${p => p.theme.headingColor};
    line-height: 30px;
    margin-top: -12px;
    margin-bottom: 38px;
  }
  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
  .left-side {
    max-width: 40%;
    text-align: left;
    @media screen and (max-width: 700px) {
      max-width: 100%;
      margin-bottom: 46px;
      text-align: center;
    }
  }
  .rigth-side {
    max-width: 40%;
    @media screen and (max-width: 700px) {
      max-width: 100%;
    }
  }
  img {
    margin-top: auto;
    max-width: 206px;
  }
`

const ContentContainer = styled.div`
  /* max-width: ${p => (p.lessWidth ? '900px' : p.theme.maxPageWidth)}; */
  max-width: ${p => (p.narrow ? '850px' : p.theme.maxPageWidth)};
`

class InfoBox extends Component {
  render() {
    const { children, smallHeading, moreMargin, narrow } = this.props
    return (
      <Container smallHeading={smallHeading} moreMargin={moreMargin}>
        <ContentContainer narrow={narrow}>{children}</ContentContainer>
      </Container>
    )
  }
}

export default InfoBox
