import styled from 'styled-components'

const Button = styled.a`
  margin-top: 8px;
  display: inline-block;
  background-color: ${p => p.theme.primaryColor};
  color: white;
  font-size: 18px;
  padding: 10px 22px;
  border-radius: 5px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.22s;
  :hover {
    transform: translateY(-1px);
    filter: brightness(105%);
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.1);
  }
  img {
    height: 20px;
    margin-bottom: -2px;
    padding-right: 10px;
  }
`

export default Button

// 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
// 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
