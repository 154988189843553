import React, { Component } from 'react'
import styled from 'styled-components'

import Button from './styles/Button'

import phoneIcon from '../images/phone.svg'
import Emphasized from './styles/Emphasized'

const TopHeading = styled.h3`
  margin-top: 70px;
  padding-left: 4px;
  color: ${p => p.theme.headingColor};
  font-size: 13.7px;
  font-weight: 600;
  letter-spacing: 2.5px;
  max-width: 420px;
  @media screen and (max-width: 800px) {
    font-size: 11.3px;
  }
  @media screen and (max-width: 650px) {
    font-size: 10.2px;
    margin-top: 40px;
  }
  @media screen and (max-width: 550px) {
    letter-spacing: 1.72px;
    font-size: 9.7px;
    margin-top: 40px;
  }
  @media screen and (max-width: 350px) {
    letter-spacing: 1.2px;
    font-size: 9.1px;
    margin-top: 48px;
  }
`

const MainHeading = styled.h1`
  margin-top: 8px;
  font-size: 54px;
  line-height: 64px;
  font-weight: 700;
  letter-spacing: -2.3px;
  color: ${p => p.theme.headingColor};
  max-width: 400px;
  @media screen and (max-width: 800px) {
    font-size: 46px;
    line-height: 56px;
    max-width: 370px;
    letter-spacing: -1.5px;
  }
  @media screen and (max-width: 650px) {
    font-size: 42px;
    line-height: 50px;
    max-width: 360px;
    letter-spacing: -1px;
  }
  @media screen and (max-width: 550px) {
    font-size: 36px;
    line-height: 42px;
    max-width: 300px;
    letter-spacing: -0.5px;
  }
  @media screen and (max-width: 350px) {
    font-size: 31.2px;
    line-height: 38px;
    max-width: 280px;
    letter-spacing: -0.1px;
  }
`

const SubHeading = styled.h2`
  margin-top: 70px;
  margin-bottom: 12px;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: -0.5px;
  max-width: 470px;
  @media screen and (max-width: 800px) {
    font-size: 26px;
    line-height: 38px;
    max-width: 410px;
  }
  @media screen and (max-width: 650px) {
    margin-top: 30px;
    font-size: 22px;
    line-height: 34px;
    max-width: 340px;
  }
  @media screen and (max-width: 550px) {
    margin-top: 30px;
    font-size: 20px;
    line-height: 28px;
    max-width: 320px;
  }
  @media screen and (max-width: 350px) {
    margin-top: 42px;
    font-size: 18px;
    line-height: 26px;
    max-width: 320px;
  }
`

class Hero extends Component {
  render() {
    return (
      <div>
        <TopHeading>REPARATUR - WARTUNG - PLANUNG - EINBAU</TopHeading>
        <MainHeading>Ihr verlässlicher Installateur im 21. Bezirk</MainHeading>
        <SubHeading>
          Rasche und unkomplizierte Hilfe im Bereich <Emphasized>Gas</Emphasized> - <Emphasized>Wasser</Emphasized> -{' '}
          <Emphasized>Heizung</Emphasized>
        </SubHeading>
        <Button href="tel:+4368181469538">
          <img src={phoneIcon} alt="" /> 0681 814 69 538
        </Button>
      </div>
    )
  }
}

export default Hero
