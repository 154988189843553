import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

class Footer extends Component {
  render() {
    return (
      <Container>
        <div>
          &copy;{' '}
          <a href="#Home" onClick={e => this.props.goToSection(e, 'Home')}>
            Franz Spanraft
          </a>
        </div>
        <button onClick={this.props.toggleImpressum}>Impressum</button>
      </Container>
    )
  }
}

export default Footer
