import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 360px;
  width: 100%;
`

class GoogleMap extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')

    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDKBr9Zmo8lN8iZQTl_bnPF_EDwnEAwLIw&callback=initMap'
    script.async = true

    document.body.appendChild(script)
  }
  render() {
    return <Container id="map" />
  }
}

export default GoogleMap
