import styled from 'styled-components'

const SectionHeader = styled.h1`
  font-size: 40px;
  line-height: 36px;
  color: ${p => p.theme.headingColor};
  padding-top: 40px;
  margin-top: ${p => (p.smallMargin ? '16px' : '36px')};
  margin-bottom: 54px;
  .sub {
    font-size: 32px;
  }
`

export default SectionHeader
