import React, { Component } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Emphasized from './styles/Emphasized'

import TextHighlight from './styles/TextHighlight'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`

const Left = styled.div`
  max-width: 60%;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
`

const Right = styled.div`
  width: 100%;
  max-width: 40%;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
`

const Portrait = styled(Img)`
  width: 100%;
  max-width: 268px;
  margin-left: auto;
  @media screen and (max-width: 900px) {
    margin-bottom: 60px;
    margin-top: -16px;
    margin-left: 0;
  }
`

class KontaktSection extends Component {
  render() {
    return (
      <Container>
        <Left>
          <p>
            <Emphasized>Meisterbetrieb Franz Spanraft</Emphasized>
            <br /> Dopschstraße 2a/Parzelle 159, 1210 Wien
            <br />
            (Nicht dauernd besetzt)
          </p>
          <p>
            Tel:{' '}
            <a className="styled-link" href="tel:+4368181469538">
              0681 814 69 538
            </a>
            <br /> E-Mail:{' '}
            <a className="styled-link" href="mailto:f.spanraft@gmx.at">
              f.spanraft@gmx.at
            </a>
          </p>
          <p>Anfragen nehme ich gerne telefonisch oder per E-Mail entgegen.</p>
          <TextHighlight>28 Jahre Erfahrung als Installateurmeister</TextHighlight>
        </Left>
        <Right>
          <Portrait fluid={this.props.portraitImage.fluid} alt="Franz Spanraft" />
        </Right>
      </Container>
    )
  }
}

export default KontaktSection
