import React from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { graphql } from 'gatsby'

import GlobalStyle from '../components/styles/GlobalStyle'
import theme from '../components/styles/theme'

import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import HeroImg from '../components/HeroImg'
import SectionHeader from '../components/styles/SectionHeader'
import LeistungenBox from '../components/LeistungenBox'
import KontaktSection from '../components/KontaktSection'
import GoogleMap from '../components/GoogleMap'
import InfoBox from '../components/InfoBox'
import IconContainer from '../components/IconContainer'
import Footer from '../components/Footer'

import Button from '../components/styles/Button'
import Emphasized from '../components/styles/Emphasized'

import iconGas from '../images/gas.svg'
import iconWasser from '../images/wasser.svg'
import iconHeizung from '../images/heizung.svg'
import wienImg from '../images/wien.svg'
import phoneIcon from '../images/phone.svg'
import PreiseSection from '../components/PreiseSection'
import Impressum from '../components/Impressum'
import MobileNav from '../components/MobileNav'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  margin-left: auto;
  margin-right: auto;
`
const ContainerOnImg = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
`

const LeistungenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 88px;
  margin-top: 52px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`

class Home extends React.Component {
  state = {
    impressumIsHidden: true,
    mobileNavIsHidden: true,
  }

  toggleImpressum = e => {
    if (e.target === e.currentTarget || e.currentTarget.tagName === 'svg') {
      this.setState(prevState => {
        return { impressumIsHidden: !prevState.impressumIsHidden }
      })
      document.body.classList.toggle('no-scroll')
    }
  }

  toggleMobileNav = () => {
    this.setState(prevState => {
      return { mobileNavIsHidden: !prevState.mobileNavIsHidden }
    })
    document.body.classList.toggle('no-scroll')
  }

  goToSection = (e, name) => {
    e.preventDefault()
    this.state.mobileNavIsHidden ? null : this.toggleMobileNav()
    const section = name ? name : e.currentTarget.innerText
    const element = document.querySelector(`#${section}`)
    element ? element.scrollIntoView({ block: 'start', behavior: 'smooth' }) : null
  }

  render() {
    const { impressumIsHidden, mobileNavIsHidden } = this.state
    const { toggleMobileNav, toggleImpressum } = this
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <div id="Home" className={this.state.impressumIsHidden ? '' : ' blurry-bg'}>
            <HeroImg headerImage={this.props.data.headerImage} />
            <ContainerOnImg>
              <GlobalStyle />
              <Navbar
                mobileNavIsHidden={mobileNavIsHidden}
                toggleMobileNav={toggleMobileNav}
                goToSection={this.goToSection}
              />
              <Hero />
            </ContainerOnImg>
            <Container>
              <SectionHeader smallMargin id="Leistungen">
                Leistungen
              </SectionHeader>
              <p>
                Ich helfe gerne bei sämtlichen Anliegen betreffend <Emphasized>Gas</Emphasized>,{' '}
                <Emphasized>Wasser</Emphasized> und <Emphasized>Heizung</Emphasized>.<br />
                Dabei übernehme ich von der Planung Ihres Bades bis hin zu Kleinst&shy;reparaturen gerne sämtliche
                Aufträge.
              </p>
              <LeistungenContainer>
                <LeistungenBox heading="Gas" icon={iconGas}>
                  <li>Erneuern oder Dichtstellen der Gasleitung</li>
                  <li>Austausch und Reparatur von Gasgeräten</li>
                  <li>Gaswerk-Meldung (bei von mir gekauften Geräten ist die Meldung kostenlos)</li>
                  <li>u.v.m.</li>
                </LeistungenBox>
                <LeistungenBox heading="Wasser" icon={iconWasser}>
                  <li>Reparatur der Armaturen</li>
                  <li>Ablaufgebrechen / Behebung verstopfter Abläufe</li>
                  <li>Austausch von Dusch-Verkleidungen</li>
                  <li>Erneuerung von Silikonnähten</li>
                  <li>Speichertausch</li>
                  <li>Duscheinbau statt Badewanne</li>
                  <li>Rohrbruch / Wasserschaden im Wohnungsbereich</li>
                  <li>u.v.m.</li>
                </LeistungenBox>
                <LeistungenBox heading="Heizung" icon={iconHeizung}>
                  <li>Einbau von Etagenheizungen</li>
                  <li>Austausch Ventile und Heizkörper</li>
                  <li>Heizungs&shy;erweiterungen</li>
                  <li>Thermentausch</li>
                  <li>Rohrbruch / Wasserschaden im Wohnungsbereich</li>
                  <li>u.v.m.</li>
                </LeistungenBox>
              </LeistungenContainer>
            </Container>
            <InfoBox narrow>
              <h1>Reparatur - Wartung - Planung - Einbau</h1>
              <h2>Sämtliche Leistungen aus einer Hand</h2>
              <p>
                Egal ob es sich um die Reparatur Ihrer Armaturen, die Wartung Ihrer Gastherme, die komplette und
                individuelle Planung Ihres Bades oder dessen Einbau handelt.
              </p>
              <p>Gerne übernehme ich auch Kleinstreparaturen.</p>
              <Button href="tel:+4368181469538">
                <img src={phoneIcon} alt="" /> 0681 814 69 538
              </Button>
            </InfoBox>
            <Container>
              <SectionHeader id="Preise">
                Preise <span className="sub">(inkl. MwSt.)</span>
              </SectionHeader>
              <PreiseSection />
            </Container>
            <InfoBox smallHeading>
              <h1>Materialien</h1>
              <p>
                Es kommen ausschließlich namhafte und qualitativ hochwertige Materialien zum Einsatz.
                <br />
                Dadurch ist die langfristige Verfügbarkeit von Ersatzteilen und die Möglichkeit eines Austausches
                gewährleistet.
              </p>
              <IconContainer />
            </InfoBox>
            <Container>
              <SectionHeader id="Kontakt">Kontakt</SectionHeader>
              <KontaktSection portraitImage={this.props.data.portraitImage} />
            </Container>
            <GoogleMap />
            <InfoBox smallHeading moreMargin>
              <h1>Auftragsannahme in ganz Wien</h1>
              <div className="wrapper">
                <div className="left-side">
                  <p>Gerne übernehme ich Aufträge in sämtlichen Wiener Gemeindebezirken.</p>
                  <p>
                    Die Fahrzeit berechnet sich dabei stets vom Firmensitz im{' '}
                    <Emphasized>21. Bezirk (Floridsdorf).</Emphasized>
                  </p>
                  <Button href="tel:+4368181469538">
                    <img src={phoneIcon} alt="" /> 0681 814 69 538
                  </Button>
                </div>
                <div className="right-side">
                  <img src={wienImg} alt="Karte von Wien" />
                </div>
              </div>
            </InfoBox>
            <Container>
              <Footer toggleImpressum={toggleImpressum} goToSection={this.goToSection} />
            </Container>
          </div>
          {!impressumIsHidden && <Impressum toggleImpressum={toggleImpressum} />}
          <CSSTransition
            timeout={350}
            classNames="mobilenav-animation"
            in={!this.state.mobileNavIsHidden}
            unmountOnExit
          >
            <MobileNav
              goToSection={this.goToSection}
              toggleMobileNav={this.toggleMobileNav}
              mobileNavIsHidden={this.state.mobileNavIsHidden}
            />
          </CSSTransition>
        </React.Fragment>
      </ThemeProvider>
    )
  }
}
export default Home

export const query = graphql`
  query Imagequery {
    portraitImage: imageSharp(original: { src: { regex: "/franz-spanraft-portrait/" } }) {
      fluid(maxWidth: 268, quality: 80) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
    headerImage: imageSharp(original: { src: { regex: "/header-image/" } }) {
      fluid(maxWidth: 3000, quality: 80, srcSetBreakpoints: [3000]) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
