import React, { Component } from 'react'
import styled from 'styled-components'

import artweger from '../images/artweger.png'
import baduscho from '../images/baduscho.png'
import grohe from '../images/grohe.png'
import hansa from '../images/hansa.png'
import idealStandard from '../images/ideal-standard.png'
import keramag from '../images/keramag.png'
import keuco from '../images/keuco.png'
import kludi from '../images/kludi.png'
import laufen from '../images/laufen.png'
import polypex from '../images/polypex.png'
import schmiedlArmaturen from '../images/schmiedl-armaturen.png'

const Container = styled.div`
  margin-top: 40px;
  /* display: flex; */
  flex-wrap: wrap;
  /* max-width: 800px; */
  margin-left: auto;
  margin-right: auto;
  /* justify-content: space-between; */
`

const LogoXs = styled.img`
  max-height: 17px;
  transition: all 0.22s;
  @media screen and (max-width: 430px) {
    max-height: 14px;
  }
  :hover {
    filter: opacity(35%);
  }
`
const LogoSm = styled.img`
  max-height: 20px;
  transition: all 0.22s;
  @media screen and (max-width: 430px) {
    max-height: 17px;
  }
  :hover {
    filter: opacity(35%);
  }
`
const LogoMd = styled.img`
  max-height: 24px;
  transition: all 0.22s;
  @media screen and (max-width: 430px) {
    max-height: 20px;
  }
  :hover {
    filter: opacity(35%);
  }
`
const LogoLg = styled.img`
  max-height: 28px;
  transition: all 0.22s;
  @media screen and (max-width: 430px) {
    max-height: 24px;
  }
  :hover {
    filter: opacity(35%);
  }
`
const LogoXl = styled.img`
  max-height: 38px;
  transition: all 0.22s;
  @media screen and (max-width: 430px) {
    max-height: 32px;
  }
  :hover {
    filter: opacity(35%);
  }
`

const FirstRow = styled.div`
  display: flex;
  margin-bottom: 28px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 28px;
  }
`
const FirstRowFirst = styled.div`
  display: flex;
  justify-content: space-around;
  width: 40%;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 32px;
    justify-content: space-evenly;
  }
`
const FirstRowSecond = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

const SecondRow = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`
const SecondRowFirst = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 28px;
  }
`
const SecondRowSecond = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

class IconContainer extends Component {
  render() {
    return (
      <Container>
        <FirstRow>
          <FirstRowFirst>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="http://www.laufen.co.at/">
                <LogoXs src={laufen} alt="Laufen Logo" />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="http://www.baduscho.at/">
                <LogoXs src={baduscho} alt="Baduscho Logo" />
              </a>
            </div>
          </FirstRowFirst>
          <FirstRowSecond>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="http://www.hansa.at/">
                <LogoXs src={hansa} alt="Hansa Logo" />
              </a>
            </div>

            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.keuco.com/">
                <LogoSm src={keuco} alt="Keuco Logo" />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.keramag.at/">
                <LogoLg src={keramag} alt="Keramag Logo" />
              </a>
            </div>
          </FirstRowSecond>
        </FirstRow>
        <SecondRow>
          <SecondRowFirst>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.artweger.at/">
                <LogoLg src={artweger} alt="Artweger Logo" />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.grohe.at/">
                <LogoLg src={grohe} alt="Grohe Logo" />
              </a>
            </div>

            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.polypex.at/">
                <LogoMd src={polypex} alt="Polypex Logo" />
              </a>
            </div>
          </SecondRowFirst>
          <SecondRowSecond>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.kludi.com/de/">
                <LogoLg src={kludi} alt="Kludi Logo" />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.idealstandard.de/">
                <LogoXl src={idealStandard} alt="Ideal-Standard Logo" />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://schmiedl.eu/">
                <LogoLg src={schmiedlArmaturen} alt="Schmiedl-Armaturen Logo" />
              </a>
            </div>
          </SecondRowSecond>
        </SecondRow>
      </Container>
    )
  }
}

export default IconContainer
