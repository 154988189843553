import React, { Component } from 'react'
import styled from 'styled-components'

import line from '../images/line.svg'

const Container = styled.div`
  padding: 36px 30px;
  max-width: 29%;
  text-align: center;
  box-shadow: 0 4px 40px rgba(228, 228, 228, 0.59);
  transition: all 0.22s;
  cursor: pointer;
  :hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 54px rgba(228, 228, 228, 0.59);
  }
  @media screen and (max-width: 1000px) {
    max-width: 100%;
    margin-bottom: 50px;
    :nth-child(3) {
      margin-bottom: 0;
    }
  }
`

const Icon = styled.img`
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 16px;
`

const Heading = styled.h1`
  color: ${p => p.theme.headingColor};
  font-weight: 600;
  margin-bottom: 40px;
`

const List = styled.ul`
  text-align: left;
  li {
    padding-left: 27px;
    position: relative;
    margin-bottom: 4px;
  }
  li::before {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 0;
    width: 12px;
    height: 12px;
    background-image: url(${line});
    background-repeat: no-repeat;
  }
`

class LeistungenBox extends Component {
  render() {
    const { heading, children, icon } = this.props
    return (
      <Container>
        <Icon src={icon} alt="" />
        <Heading>{heading}</Heading>
        <List>{children}</List>
      </Container>
    )
  }
}

export default LeistungenBox
