import React, { Component } from 'react'
import styled from 'styled-components'
import TextHighlight from './styles/TextHighlight'
import Emphasized from './styles/Emphasized'

const Container = styled.div`
  display: flex;
  margin-bottom: 28px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 42px;
  }
`

const Left = styled.div`
  width: 35%;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 20px;
  }
`
const Heading2 = styled.h2`
  font-size: 22px;
  font-weight: 600;
  color: ${p => p.theme.headingColor};
  @media screen and (max-width: 800px) {
    font-size: 26px;
  }
`
const Right = styled.div`
  width: 65%;
  margin-top: 2px;
  @media screen and (max-width: 800px) {
    margin-top: -1px;
    width: 100%;
  }
`

const Wrapper = styled.div``

const SubContainer = styled.div`
  display: flex;
  position: relative;
  @media screen and (max-width: 550px) {
    flex-direction: column;
    margin-bottom: 8px;
    :nth-child(5) {
      margin-bottom: 0;
    }
  }
`

const SubLeft = styled.ul``

const SubRight = styled.ul`
  /* padding-left: 100px; */
  position: absolute;
  left: 260px;
  @media screen and (max-width: 900px) {
    left: 220px;
    /* padding-left: 60px; */
  }
  @media screen and (max-width: 850px) {
    left: 200px;
    /* padding-left: 30px; */
  }
  @media screen and (max-width: 550px) {
    position: relative;
    left: 0;
  }
`

class PreiseSection extends Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <Left>
            <Heading2>Arbeitszeit</Heading2>
          </Left>
          <Right>
            <Emphasized>Stundensatz</Emphasized>
            <p style={{ marginBottom: '20px' }}>€ 90,- / Stunde (kleinste Einheit = 1/2 Stunde)</p>
            <Emphasized>Fahrzeit</Emphasized>
            <p>
              Die Fahrzeit gilt als Arbeitszeit
              <br />
              Die Berechnung erfolgt vom Firmensitz im 21. Bezirk in Wien (Floridsdorf)
            </p>
          </Right>
        </Container>
        <Container>
          <Left>
            <Heading2>Pauschalen</Heading2>
          </Left>
          <Right>
            <Emphasized>Wartung und Reparatur</Emphasized>
            <SubContainer>
              <SubLeft>
                <li>Gaskonvektor:</li>
              </SubLeft>
              <SubRight>
                <li>€ 100,-</li>
              </SubRight>
            </SubContainer>
            <SubContainer>
              <SubLeft>
                <li>Therme:</li>
              </SubLeft>
              <SubRight>
                <li>€ 130,- (nur Vaillant und Junkers)</li>
              </SubRight>
            </SubContainer>
            <SubContainer>
              <SubLeft>
                <li>Durchlauferhitzer 10ltr:</li>
              </SubLeft>
              <SubRight>
                <li>€ 100,- (nur Vaillant und Junkers)</li>
              </SubRight>
            </SubContainer>
            <SubContainer>
              <SubLeft>
                <li>Durchlauferhitzer 5ltr:</li>
              </SubLeft>
              <SubRight>
                <li>€ 80,- (nur Vaillant und Junkers)</li>
              </SubRight>
            </SubContainer>
            {/* <li>Therme:</li> */}
            {/* <li>Durchlauferhitzer 10ltr:</li> */}
            {/* <li>Durchlauferhitzer 5ltr:</li> */}
            {/* </SubLeft> */}
            {/* <SubRight> */}
            {/* <li>€ 95,-</li> */}
            {/* <li>€ 125,- (nur Vaillant und Junkers)</li> */}
            {/* <li>€ 95,- (nur Vaillant und Junkers)</li> */}
            {/* <li>€ 75,- (nur Vaillant und Junkers)</li> */}
            {/* </SubRight> */}
            {/* </SubContainer> */}
            <br />
            <p style={{ marginBottom: '20px' }}>
              10% Rabatt bei der Wartung von 2 Geräten
              <br />
              <br />
              Die Fahrzeit ist nicht inkludiert, Material und Einbau vom Material werden gesondert verrechnet
            </p>
            <Emphasized>Gaswerk-Meldung</Emphasized>
            <br />
            <p style={{ marginBottom: '20px' }}>
              Kostenlos, wenn Sie das Gerät bei mir gekauft haben (ansonsten € 60,-)
            </p>
            <Emphasized>Kostenvoranschlag</Emphasized>
            <br />
            <p>
              € 140,- bei Planungen für Bad + Heizung + Gas
              <br />
              Bei Auftragserteilung erfolgt eine Rückerstattung des Preises
            </p>
          </Right>
        </Container>
        <Container>
          <Left>
            <Heading2>Zahlungsbedingungen</Heading2>
          </Left>
          <Right>
            <Emphasized>Sofortbezahlung</Emphasized>
            <br />
            <p style={{ marginBottom: '20px' }}>Reparaturen bis € 300,- und Servicepauschalen</p>
            <Emphasized>Zahlung auf Rechnung</Emphasized>
            <br />
            Planungen für Bad + Heizung + Gas
            <br />
            1. Drittel bei Auftragserteilung
            <br />
            2. Drittel bei Arbeitsbeginn
            <br />
            3. Drittel bei Rechnungslegung
          </Right>
        </Container>
        <TextHighlight>Korrekte und faire Abrechnung gemäß obiger Preisliste </TextHighlight>
      </Wrapper>
    )
  }
}

export default PreiseSection
