import React from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import Emphasized from './styles/Emphasized'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: white;
  z-index: 12;
  transition: all 0.15s;
  transition: all 0.15s;
`

const Text = styled.div`
  padding-top: 80px;
  padding-left: 15vw;
`

const ListContainer = styled.ul`
  padding-bottom: 30px;
  font-size: 40px;
  line-height: 74px;
  color: ${p => p.theme.headingColor};
  @media (max-width: 350px) {
    font-size: 32px;
    line-height: 60px;
  }
`

const Divider = styled.div`
  width: 64px;
  height: 2px;
  background-color: ${p => p.theme.primaryColor};
  margin-top: 14px;
  margin-bottom: 26px;
`

const BurgerMenu = styled.div`
  position: absolute;
  top: 42px;
  right: 30px;
  width: 30px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
`

const BurgerMenuIcon = styled.span`
  display: block;
  width: 26px;
  height: 3px;
  background-color: white;
  border-radius: 10px;
  transform: translateY(10px);
  transition: 0.1s;
  &.mobilenav-icon-animation-enter-active {
    background-color: transparent;
  }
  &.mobilenav-icon-animation-enter-active:before {
    background-color: ${p => p.theme.primaryColor};
    transform: translateY(0px) rotate(45deg);
  }
  &.mobilenav-icon-animation-enter-active:after {
    background-color: ${p => p.theme.primaryColor};
    transform: translateY(-3px) rotate(-45deg);
  }
  &.mobilenav-icon-animation-enter-done {
    background-color: transparent;
  }
  &.mobilenav-icon-animation-enter-done:before {
    background-color: ${p => p.theme.primaryColor};
    transform: translateY(0px) rotate(45deg);
  }
  &.mobilenav-icon-animation-enter-done:after {
    background-color: ${p => p.theme.primaryColor};
    transform: translateY(-3px) rotate(-45deg);
  }
  &:before {
    content: '';
    display: block;
    width: 26px;
    height: 3px;
    background-color: white;
    transform: translateY(-8px);
    border-radius: 10px;
    transition: 0.15s ease-out;
  }
  &:after {
    content: '';
    display: block;
    width: 26px;
    height: 3px;
    background-color: white;
    transform: translateY(5px);
    border-radius: 10px;
    transition: 0.15s ease-out;
  }
`

const AdditionalText = styled.div`
  font-size: 13px;
  line-height: 20px;
  padding-bottom: 20px;
`

const Vertiline = styled.span`
  color: ${p => p.theme.primaryColor};
  padding-left: 1px;
  padding-right: 1px;
`

class MobileNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
    }
    this.handleCloseButton = this.handleCloseButton.bind(this)
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    })
  }

  handleCloseButton() {
    this.setState(
      {
        mounted: false,
      },
      () => this.props.toggleMobileNav()
    )
  }

  render() {
    return (
      <Container>
        <Text>
          <ListContainer>
            <li>
              <a href="#Leistungen" onClick={this.props.goToSection}>
                Leistungen
              </a>
            </li>
            <li>
              <a href="#Preise" onClick={this.props.goToSection}>
                Preise
              </a>
            </li>
            <li>
              <a href="#Kontakt" onClick={this.props.goToSection}>
                Kontakt
              </a>
            </li>
          </ListContainer>
          <Divider />
          <AdditionalText>
            <Emphasized>Installateurmeister Franz Spanraft</Emphasized>
            <br />
            Gas <Vertiline>|</Vertiline> Wasser <Vertiline>|</Vertiline> Heizung
            <br />
            Tel:{' '}
            <a className="styled-link" href="tel:+4368181469538">
              0681 814 69 538
            </a>
            <br /> E-Mail:{' '}
            <a className="styled-link" href="mailto:f.spanraft@gmx.at">
              f.spanraft@gmx.at
            </a>
          </AdditionalText>
        </Text>
        <BurgerMenu onClick={this.handleCloseButton}>
          <CSSTransition timeout={350} classNames="mobilenav-icon-animation" in={this.state.mounted} unmountOnExit>
            <BurgerMenuIcon className="" />
          </CSSTransition>
        </BurgerMenu>
      </Container>
    )
  }
}
export default MobileNav
