const theme = {
  maxPageWidth: '1160px',
  paddingOnSides: '35px',
  primaryColor: '#00ADEF',
  bodyColor: '#5E5E5E',
  headingColor: '#323232',
  backgroundColor: '#F9F9F9',
}

export default theme
