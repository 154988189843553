import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ::selection {
    background: ${p => p.theme.primaryColor};
    color: white;
  }
  body {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: ${p => p.theme.bodyColor}
  }
  ul {
    list-style: none;
    padding: 0;
    margin:0;
  }
  button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: inherit;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .styled-link {
    text-decoration: underline;
  }
  .blurry-bg {
    filter: blur(4px);
  }
  .no-scroll {
  overflow: hidden;
  }

  .mobilenav-animation-enter {
    opacity: 0;
  }
  .mobilenav-animation-enter-active {
    opacity: 1;
  }
  .mobilenav-animation-exit {
    opacity: 1;
  }
  .mobilenav-animation-exit-active {
    opacity: 0;
  }
`
export default GlobalStyle
