import React from 'react'
import styled from 'styled-components'
// import headerImage from '../images/header-image.jpg'
import Img from 'gatsby-image'

const HeroImage = styled(Img)`
  width: 100%;
  height: 800px;
  @media screen and (max-width: 650px) {
    height: 600px !important;
  }
  img {
    object-fit: cover !important;
    filter: hue-rotate(-25deg) !important;
    @media screen and (max-width: 1000px) {
      object-position: 62% 50% !important;
    }
    @media screen and (max-width: 650px) {
      /* height: 600px !important; */
      object-position: 60% 50% !important;
    }
    @media screen and (max-width: 650px) {
      object-position: 10% 50% !important;
    }
  }
`

const HeroImg = props => <HeroImage fluid={props.headerImage.fluid} alt="Bad-Armaturen" />

export default HeroImg
